import { defineStore } from 'pinia'

interface ProductOrder {
  product_name: string
  price: string
  quantity: number
}

interface UserOrder {
  id: string
  delivery_city: string
  delivery_date: string
  products: ProductOrder[]
  order_value: string
  discount: null | string
  deleted: boolean
  customer_message: null | string
  created_at: string
  updated_at: string
}

export interface User {
  name: string
  postalNumber: string
  city?: string
  telephone: string
  email: string
  auth?: boolean
  orders?: UserOrder[]
  telephoneCountry?: string
}

interface UserState {
  user: User | null
}

export const useUserStore = defineStore('user', {
  state: (): UserState => ({
    user: {
      name: '',
      postalNumber: '',
      email: '',
      telephone: '',
      city: '',
      auth: false,
      orders: [],
      telephoneCountry: '',
    },
  }),
  actions: {
    setUser(data: User | null) {
      this.user = data
    },
  },
  getters: {
    isUserLoggedIn(): boolean {
      return this.user ? this.user.auth === true : false
    },
    userOrders(): UserOrder[] | null {
      return this.user ? this.user.orders || [] : null
    },
  },
})
